.categories-nav{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}

.filter-btn{
  width: 100%;
  height: 100%;
  border: none;
  padding: 30px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: bold;
  color: #551B54;
  transition: all .5s ease-in-out;
}

@media (max-width:500px){
  .categories-nav{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.active{
  background: #551B54;
  color: #fff;
  
}

.courses-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

@media (max-width:500px){
  .courses-container{
    grid-template-columns: 1fr;
  }
}

.course-container{
  height: 300px;
  width: 100%;
  background-color: #551b5444;
  color: #551B54;
  padding: 10px 0 0 20px;
  position: relative;
  border-radius: 2px;
  cursor: pointer;
  font-weight: bold;
  transition: all .5s ease-in-out;
  z-index: 1;
}

.course-container:hover{
  background-color: #551B54;
  color: #fff;
  
}

.course-img{
  width: 150px;
  height: 150px;
  object-fit: contain;
  position: absolute;
  bottom: 10px;
  right: 10px;
  transition: all .5s ease-in-out;
}

.course-img:hover{
  width: 160px;
  height: 160px;
}
