.about-section{
  margin: 200px 0;
  display: flex;
  align-items: center;
}

@media (max-width:600px){
  .about-section{
    flex-direction: column;
  }
}

.left-section, .right-section{
  flex-basis: 50%;
}

.left-section{
  position: relative;

}

.position-img{
  position: absolute;
  height: 200px;
  width: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.alpha-img{
  position: relative;
  width: 400px;
  height: 500px;
}

.img-top{
  top: -20%;
  right: 20%;
  z-index: 1;
}

.img-middle{
  top: 50%;
  left: -20%;
  transform: translateY(-50%);
  z-index: 1;
}

.img-bottom{
  bottom: -20%;
  right: 20%;
}

@media (max-width:500px){
  .left-section, .right-section{
    margin: 60px 0;
  }
  .position-img{
    height: 100px;
    width: 160px;
  }
  
  .alpha-img{
    width: 250px;
    height: 300px;
  }

  .img-top{
    top: -20%;
    right: -20%;
    z-index: 1;
  }
  
  .img-middle{
    top: 50%;
    left: -20%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .img-bottom{
    bottom: -20%;
    right: -20%;
  }

 }