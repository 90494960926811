.course-details{
  margin-top: 50px;
  display: flex;
  gap: 50px;
}

.course-details h2{
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
}

.course-details h3{
  font-size: 22px;
}

@media (max-width:500px){
  .course-details{
    flex-direction: column;
    gap: 30px;
  }

  .section-nav h1{
    font-size: 25px;
  }
}

.left-content{
  flex-basis: 70%;
}

.right-content{
  flex-basis: 30%;
}

.section-container p{
  font-size: 17px;
}

p span{
  font-weight: bold;
  padding-right: 10px;
}

.course-details ul li{
  list-style-type: square;
  font-size: 17px;
}

.location{
  margin-bottom: -30px;
}