.section-container{
  margin-top: 100px;
}

.section-nav{
  background: #551B54;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.section-nav h1{
  font-size: 40px;
  font-weight: 500;
}

.courses-section{
  margin-top: 50px;
}