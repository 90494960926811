.services-section{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 70px;
  min-height: 300px;
}

.services-intro-text{
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-intro-text p{
  width: 50%;
}

@media (max-width:500px){
  .services-intro-text p{
    width: 90%;
  }
}

.goal-card{
  padding: 20px;
 transition: all .5s ease-in-out;
}

.goal-card:hover{
  box-shadow: -1px 0px 6px -2px rgba(0,0,0,0.95);
  border-radius: 5px;
}

.services-section h5{
  text-transform: capitalize;
  font-weight: bold;
  color: #551B54;
  font-size: 18px;
}

.services-section img{
  margin-bottom: 30px;
}

@media (max-width:500px){
.services-section{
  grid-template-columns: 1fr;
}
}