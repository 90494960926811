.goals-container{
  display: flex;
  justify-content: space-around;
  gap: 40px;
}

.goal-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 30%;
  text-align: center;
}

.goal-card img{
  width: 80px;

}

.goal-card h4{
  color: #551B54;
  font-size: 24px;
  margin-top: 20px;
}

.goal-card p{
  font-size: 16px;
}

@media (max-width:500px){
  .goals-container{
    flex-direction: column;
  }
}