.footer-container{
  background-color: #551B54;
  color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 100px 15%;
  gap: 50px;
  margin-top: 50px;
}

.foot{
  position: absolute;
  bottom: 0;
  left: 0;
}


.footer-card{
  flex-basis: 30%;
}

.footer-card:nth-child(2){
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-card h5{
  font-weight: 300;
  font-size: 20px;
  text-decoration: underline;
}

.addrress-title{
  margin: 20px 0 -20px;
}

.footer-card p{
  font-size: 16px;
  font-weight: 200;
}

.footer-card .line-container{
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: -30px;
}

.footer-card .line-container .icon-card{
  flex-basis: 10%;
  font-size: 25px;
}

.footer-card .line-container p, .footer-card .line-container div{
  flex-basis: 90%;
}

.socials{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.socials .social-icon{
  font-size: 30px;
  transition: all .5s ease-in-out;
}

.socials .social-icon:hover{
  transform: scale(.8);
  color: #a86aa7;
}

.footer-card .quick-links li{
  margin: 20px 0;
}

.footer-card a{
  color: #fff;
  font-size: 16px;
  font-weight: 200;
}

.footer-bottom{
  background-color: #3f143e;
}

.footer-bottom p{
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 15px 0;
  font-weight: 300;
}

@media (max-width:500px){
  .footer-container{
    flex-direction: column-reverse;
  }

  .footer-card:nth-child(2){
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
}