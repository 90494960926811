.nav-container{
  background-color: #fff;
  box-shadow: 0px 10px 5px -1px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.mobile-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 5px;
}

.logo{
  width: 200px;
}

.hamburger-icon{
  font-size: 40px;
  background-color: #551B54;
  border: 2px solid #551B54;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.hamburger-icon:hover{
  background-color: #fff;
  color: #551B54;
}

.link{
  padding: 10px 20px;
  color: #551B54;
  display: block;
  transition: all .5s ease-in-out;
}

.link:hover{
  background-color: #551B54;
  color: #fff;
}

.nav-links-container{
  height: 0;
  overflow: hidden;
}

.nav-links{
  font-weight: 600;
}

.show-nav{
  height: 100%;
}


.cta{
  background-color: #551B54;
  border: 2px solid #551B54;
  /* padding: 15px; */
  color: #fff;
  cursor: pointer;
  transition: all .5s ease-in-out;
  border-radius: 2px;
}

.cta:hover{
  background-color: #fff;
  color: #551B54;
}

@media (min-width:800px){
  .nav-container{
    padding: 0 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hamburger-icon{
    display: none;
  }

  .nav-links-container{
    height: auto !important;
  }

  .nav-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .link{
    margin: 0 10px;
    border-radius: 5px;
  }

  .link:hover{
    background-color: #551b5413;
    color: #551B54;
  }
}
