.about-container{
  width: 100%;
  margin: 70px 0;
}

.section-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

h3{
  font-size: 40px;
  color: #551B54;
}

.line{
  width: 70px;
  height: 10px;
  border-radius: 20px;
  background: #551B54;
}

.about-content-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 50px;
  gap: 50px;
}

.about-img img{
  width: 400px;
  height: 500px;
  object-fit: cover;
  border-radius: 5px;
}

.about-content{
  flex-basis: 50%;
}

.about-content p{
  font-size: 16px;
  line-height: 27px;
}

.about-content b{
  color: #551B54;
}

@media (max-width:500px){
  .about-container{
    margin: -100px 0 20px;
  }
  .about-content-container{
    flex-direction: column;
    padding: 0 20px;
  }
}