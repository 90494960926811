@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,500&display=swap');

*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Poppins', sans-serif;
  background: #fff;
}

.all-cont{
  min-height: 100vh;
  position: relative;
}

.foot-cont{
  padding-bottom: 800px;
}

@media (max-width:500px){
  .foot-cont{
    padding-bottom: 1500px;
  }
}

img{
  max-width: 100%;
}

li{
  list-style: none;
}

a{
  text-decoration: none;
}

.container{
  padding: 0 15%;
}

@media (max-width:500px){
  .container{
    padding: 0 2%;
  }
}