@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&display=swap');

.carousel-cont{
  height: 100vh;
}

.carousel-slide-cont{
  height: 100%;
  position: relative;
}

.carousel-img{
  height: 100vh;
  object-fit: cover;
}

.text-container{
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.822);
  width: 40%;
  text-align: left;
  border-radius: 5px;
}

.inner-text{
  display: block;
}

.outer-text{
  display: none;
}

@media (max-width:600px){
  .carousel{
    height: 70vh;
  }

  .carousel-img{
    height: 70vh;
    object-fit: cover;
  }

  .text-container{
    position: unset;
    width: 100%;
  }

  .outer-text{
    display: block;
  }

  .inner-text{
    display: none;
  }
}

.hero-content{
  flex-basis: 50%;
  padding: 40px;
}

h2{
  font-family: 'Balsamiq Sans', cursive;
  font-size: 50px;
  color: #551B54;
}

p{
  margin: 30px 0;
  font-size: 20px;
}

.btn{
  background-color: #551B54;
  border: 4px solid #551B54;
  padding: 20px 30px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  transition: all .5s ease-in-out;
  border-radius: 2px;
}

.btn:hover{
  background-color: #fff;
  color: #551B54;
}

@media (max-width: 600px){
  .hero-content{
    padding: 20px;
  }
  h2{
    font-size: 45px;
  }
  
  p{
    font-size: 18px;
  }
  
  .btn{
    padding: 18px 25px;
    font-size: 16px;
  }
}