.contact-section{
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.left-contact-box{
  background: #EFEFEF;
  padding: 30px;
  border-radius: 5px;
}

.contact-text{
  color: #551B54;
}

.left-section, .right-section{
  flex-basis: 50%;
}

form{
  width: 100%;
}

@media (max-width:500px){
  .contact-section{
    flex-direction: column;
  }

  .details-card{
    padding: 0 30px;
  }

  form{
    width: 100%;
  }
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

.form-field{
  margin: 20px 0;
  width:100%;
}

.form-control{
  width: 100%;
  padding: 20px;
  outline: none;
  border-radius: 3px;
  border: 2px solid #000;
}

.form-control:focus{
  border: 2px solid #551B54;;
}